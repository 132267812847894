<template>
  <div class="create-mobile-draft-dialog">
    <v-form @submit.prevent="createDraftHandle">
      <v-row no-gutters>
        <v-col cols="12">
          <template-carousel
            v-model="selectedTemplate"
            :items="templates"
            :loading="loadingTemplates"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import api from "@/lib/calendesk-js-library/api/api";
import mobileActions from "@/calendesk/mixins/mobileActions";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";

export default {
  name: "CreateMobileDraftDialog",
  components: {
    TemplateCarousel: () =>
      import("./CreateDraftDialog/components/TemplateCarousel"),
  },
  mixins: [mobileActions],
  data() {
    return {
      selectedTemplate: null,
      loading: false,
      templates: [],
      loadingTemplates: false,
    };
  },
  watch: {
    selectedTemplate: function () {
      this.createDraftHandle();
    },
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    createDraftHandle() {
      this.loading = true;
      this.$emit("loading", this.loading);
      api
        .createMobileDraft({
          template_id: this.selectedTemplate.id,
          description: this.selectedTemplate.name,
        })
        .then(({ data }) => {
          this.handleEditMobileAppClick(data);
          this.$emit("close");
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("loading", this.loading);
        });
    },
    getTemplates() {
      this.loadingTemplates = true;
      api
        .getMobileTemplates()
        .then(({ data }) => {
          this.templates = data.data;
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.loadingTemplates = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-mobile-draft-dialog {
  padding: 0 40px;
}

.create-mobile-draft-dialog_name-input {
  margin: 0;
}

.create-mobile-draft-dialog_category_title {
  margin-top: 0;
}
</style>
